@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

    @keyframes color {
        0% {
            background-color: #cb0000
        }
        10% {
            background-color: #d17803
        }
        20% {
            background-color: #ffbf00
        }
        30% {
            background-color: #56d000
        }
        40% {
            background-color: #1f937c
        }
        50% {
            background-color: #00e5e5
        }
        60% {
            background-color: #01009b
        }
        70% {
            background-color: #4a0061
        }
        80% {
            background-color: #9e005b
        }
        90% {
            background-color: #d60e67
        }
        100% {
            background-color: #9e000f
        }
    }

    @keyframes bounce {
        $b: (x: 0, y: 0, vx: 0.25, vy: 0.15, color: 0);
        $count: 56;
        $shouldUpdateColor: false;
        @for $i from 0 through $count {
            $percent: 0% + $i / $count * 100;
            #{$percent} {
                transform: translate(
                    calc(#{map-get($b, x)} * (100vw - 200px)),
                    calc(#{map-get($b, y)} * (100vh - 184px))
                );
                filter: hue-rotate(#{map-get($b, color)}deg) saturate(200%) brightness(125%);
            }
            $b: map-merge(
                $b,
                (
                    x: max(0, min(1, map-get($b, x) + map-get($b, vx))),
                    y: max(0, min(1, map-get($b, y) + map-get($b, vy)))
                )
            );
            $changed: false;
            @if map-get($b, x) >= 1 or map-get($b, x) <= 0 {
                $b: map-merge($b, (vx: map-get($b, vx) * -1));
                $changed: true;
            }
            @if map-get($b, y) >= 1 or map-get($b, y) <= 0 {
                $b: map-merge($b, (vy: map-get($b, vy) * -1));
                $changed: true;
            }
            @if $changed {
                $shouldUpdateColor: true;
            }
            @else if $shouldUpdateColor {
                $shouldUpdateColor: false;
                $b: map-merge($b, (color: map-get($b, color) + 137.5));
            }
        }
    }

    .p-bounce-animation:before {
        content: '';
        display: block;
        background: url(https://cdn.pagisto.com/two/pagisto/graphics/logos/pagistoni_only_head.svg);
        width: 200px;
        top: 0;
        left: 0;
        z-index: 3;
        height: 184px;
        background-repeat: no-repeat;
        position: fixed;
        animation: 50s linear bounce infinite running;
    }

    .p-color-animation {
        width: 133px;
        height: 123px;
        mask-image: url('https://cdn.pagisto.com/two/pagisto/graphics/logos/pagistoni_only_head.svg');
        animation: 5s linear color infinite running;
        mask-size: contain;
        mask-position: center;
        mask-repeat: no-repeat;
    }

    @media (max-width: 1080px) {
        .p-bounce-animation:before {
            content: '';
            display: block;
            background: url(https://cdn.pagisto.com/two/pagisto/graphics/logos/pagistoni_only_head.svg);
            width: 200px;
            top: 0;
            left: 0;
            z-index: 3;
            height: 184px;
            background-repeat: no-repeat;
            position: fixed;
            animation: 25s linear bounce infinite running;
        }
    }
